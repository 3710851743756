const ru = {
  auth: {
    login: "Войти",
    loginLink: "/giris",
    reset: {
      button: "Создать новый пароль",
      button2: "Сброс пароля",
      description:
        "Введите адрес электронной почты, с которого вы зарегистрировались, и мы отправим вам электронное письмо для сброса пароля.",
      errors: {
        emailError:
          "Произошла ошибка при попытке отправить вам электронное письмо. Пожалуйста, свяжитесь с нами по адресу iletisim@agrowy.com",
        empty: "Она не может быть пустой.",
        expiredRequest:
          "Срок действия этого запроса на сброс истек. Вернитесь на страницу сброса пароля и отправьте новый запрос.",
        noMatch: "Пароли не совпадают",
        noUserFound: "С этим письмом не найдено ни одного пользователя.",
        social:
          "Похоже, вы зарегистрировались в социальной сети. У вас нет пароля для сброса. Пожалуйста, перейдите на страницу входа и попробуйте использовать свой социальный аккаунт.",
        unexpected:
          "Произошла непредвиденная ошибка. Пожалуйста, свяжитесь с нами, чтобы решить эту проблему.",
      },
      label1: "Новый пароль",
      label2: "Подтвердите новый пароль",
      label3: "Почта",
      loginLink: "Логин",
      loginLinkDescription: "Запомнили пароль?",
      placeholder1: "Введите новый пароль",
      placeholder2: "Подтвердите новый пароль.",
      placeholder3: "Введите адрес электронной почты",
      success: {
        changed:
          "Вы успешно сменили пароль и перешли на страницу входа в систему.",
        emailSent: "Мы отправили вам электронное письмо для смены пароля.",
      },
      title: "Сбросить пароль",
      title2: "Запрос на сброс пароля",
    },
    signup: "Зарегистрироваться",
    signupLink: "/kayit",
  },
  blog: {
    noSources: "Для этой статьи нет источников.",
    productTitle: "Похожие товары",
    sources: "Источники",
    summary: "Сводка",
  },
  bottomReactions: {
    vote: {
      question: "Является ли этот отзыв ценным для вас?",
      title: "Голосовать",
    },
  },
  breadcrumb: {
    product: "Продукты",
  },
  chatbox: {
    header: {
      title: "Служба поддержки",
    },
    home: {
      averageTime: "Среднее время ответа: ",
      averageTimeText: "От 1 до 2 минут.",
      button: "Начать новый чат",
      description:
        "Мы здесь, чтобы помочь вам. Пожалуйста, начните новый чат или выберите существующий.",
      previousChats: "Предыдущие чаты",
      title: "Добро пожаловать!",
    },
    input: {
      placeholder: "Текст сообщения",
    },
    unauthenticated: {
      anonymous: "Продолжить без регистрации",
      description:
        "Зарегистрируйтесь бесплатно, чтобы получить быструю и постоянную поддержку от нашей службы поддержки.",
      login: "Логин",
      loginText: "Уже есть учетная запись?",
      privacyText:
        "Мы заботимся о вашей конфиденциальности. Подробности вы можете прочитать в политике конфиденциальности.",
      signup: "Зарегистрироваться",
      title:
        "Пожалуйста, зарегистрируйтесь, чтобы получить поддержку прямо сейчас.",
    },
    ctaMessage: {
      title1: "У вас есть вопрос?",
      title2: "Мы здесь чтобы помочь вам.",
      onlineText: "Поддержка онлайн.",
      button: "Начать новый чат",
    },
  },
  company: {
    buttons: {
      seeProducts: "Посмотреть продукты",
    },
    product: "Продукты",
    subcategory: "Подкатегории",
  },
  companyPage: {
    about: {
      contactTitle: "Контактная информация",
    },
    cta: {
      button: "Получите поддержку в реальном времени",
      message: "У вас есть вопрос? Обратитесь в службу поддержки прямо сейчас.",
    },
    header: {
      productsButton: "Посмотреть все продукты",
      productsNumber: "Продукты",
      supportButton: "Поддержка",
    },
    sidebar: {
      about: "О программе",
      news: "Новости",
      products: "Продукты",
    },
  },
  contactUs: {
    Address: "Адрес",
    Company: "Компания",
    CompanyName: "Название компании",
    Contact: "Контакт",
    Email: "Почта",
    Founders: "Основатели",
    Office: "Офис",
    Phone: "Телефон",
    TaxID: "Номер налогоплательщика",
  },
  dataTips: {
    close: "Закрыть",
    edit: "Редактировать",
    emoji: "Добавить смайлик",
    image: "Загрузить изображение",
    send: "Отправить",
  },
  deleteConfirmationPopup: {
    closeButton: "Закрыть",
    deleteButton: "Подтвердить",
    infoText: "Удаление контента не может быть отменено!",
    text: "Вы уверены, что удалите это? (Вы не можете повторить эту операцию.)",
    title: "Подтвердить операцию удаления",
  },
  footer: {
    copyrightText: "© Все права защищены. agrowy",
    footerLinks: [
      [
        {
          link: "/tarim-urunleri?category=4be0839c-0c62-48f2-94b2-8cc3844a1242",
          title: "Подкормка растений",
        },
        {
          link: "/tarim-urunleri?category=cc22ca79-e616-4b75-be8e-adc8fa58baf2",
          title: "Защита растений",
        },
        {
          link: "/tarim-urunleri?category=98ad225d-8e31-458a-89ab-da80db2d9339",
          title: "Семена и саженцы",
        },
        {
          link: "/tarim-urunleri?category=37d3bbe6-839e-4aeb-9047-1e43b17b8119",
          title: "Системы орошения",
        },
        {
          link: "/tarim-urunleri?category=4224995e-4cb9-4178-88d7-c166b6f0e1d6",
          title: "Теплицы и оборудование",
        },
      ],
      [
        {
          link: "/tarim-urunleri",
          title: "Списки продуктов",
        },
        {
          link: "/tarim-sirketleri",
          title: "Список компаний",
        },
        {
          link: "/tarim-fuarlari-takvimi",
          title: "Календарь событий",
        },
      ],
      [
        {
          link: "/agrowy-nedir",
          title: "Что такое agrowy?",
        },
        {
          link: "/iletisim",
          title: "Связаться с нами",
        },
        {
          link: "/kvkk-kapsaminda-aydinlatma-metni",
          title: "GDPR",
        },
        {
          link: "/cerez-politikasi",
          title: "Политика использования файлов cookie",
        },
      ],
    ],
    footerTitles: {
      title1: "Категории продуктов",
      title2: "Быстрые ссылки",
      title3: "Компания",
    },
    miniCtaBox: {
      buttonTitle: "Поддержка",
      description: "Вам нужна консультация по сельскому хозяйству в Турции?",
      heading: "Справка",
      link: "/link",
    },
    slogan:
      "Найдите новые сельскохозяйственные продукты и компании за считанные секунды, чтобы развивать свой бизнес.",
  },
  formInputs: {
    emailErrors: {
      empty: "Укажите email-адрес",
      rule1: "Вы ввели недействительный адрес электронной почты.",
    },
    emailLabel: "Почта",
    emailPlaceholder: "Введите адрес электронной почты",
    fnameErrors: {
      empty: "Требуется указать имя.",
      rule1: "Имя должно содержать не менее двух символов.",
    },
    fnameLabel: "Имя",
    fnamePlaceholder: "Введите свое имя",
    lnameErrors: {
      empty: "Необходимо указать фамилию",
      rule1: "Фамилия должна содержать не менее двух символов.",
    },
    lnameLabel: "Фамилия",
    lnamePlaceholder: "Введите вашу фамилию",
    passwordErrors: {
      empty: "Требуется пароль.",
      rule1:
        "Пароль должен содержать не менее 6 символов без пробелов, содержать хотя бы одну цифру и заглавную букву.",
    },
    passwordLabel: "Пароль",
    passwordPlaceholder: "Введите свой пароль",
    saveButton: "Сохранить",
  },
  globalSearch: {
    noHistoryMessage: "Найдите все, что вам нужно, в одном месте!",
    placeholder: "Поиск компаний, продуктов, выставок и многого другого...",
    results: "Результаты",
    searchHistory: "Предыдущие поиски",
    types: {
      all: "Все",
      company: "Компании",
      post: "Сообщений",
      product: "Продукты",
      trade_show: "Выставки",
    },
  },
  homeINT: {
    featuredProducts: {
      title: "Рекомендуемые продукты",
    },
    featuredCompanies: {
      title: "Рекомендуемые компании",
    },
    footer: {
      title1: "Свяжитесь",
      title2: "с проверенными сельскохозяйственными",
      title3: "компаниями на вашем языке.",
      chatButton: "НАЧАТЬ ЖИВОЙ ЧАТ",
      boxTitle: "МЫ ГОВОРИМ НА ВАШЕМ ЯЗЫКЕ",
      boxDescription:
        "Наши агенты могут говорить на русском, арабском, английском, испанском, персидском и многих других языках.",
    },
    boxes: {
      companies: {
        button: "См. Компании",
        description:
          "Найдите эксклюзивные компании для создания новых партнерских отношений.",
        title: "Компании",
      },
      events: {
        button: "ПОСМОТРЕТЬ СОБЫТИЯ",
        description:
          "Найдите лучшие мероприятия, соответствующие вашим потребностям, и получите информацию.",
        title: "События",
      },
      getHelp: {
        button: "Начать онлайн-чат",
        description: "Вам нужна консультация по сельскому хозяйству в Турции?",
        title: "Справка",
      },
      products: {
        button: "Посмотреть продукты",
        description:
          "Откройте для себя более 1.000 сельскохозяйственных продуктов и отфильтруйте то, что вам нужно.",
        title: "Продукты",
      },
    },
    hero: {
      callout1: "1000+ продуктов",
      callout2: "Эксклюзивные компании",
      callout3: "Многоязычная поддержка",
      callout4: "Онлайн-чат с агентом",
      paragraph1:
        "Вы ищете новые продукты или новые компании для создания бизнеса?",
      paragraph2:
        "Найдите все необходимое в одном месте для развития вашего сельскохозяйственного бизнеса.",
      title1: "Найти",
      title2: "лучшие сельскохозяйственные продукты",
      title3: "в одном месте.",
      what: "Что такое",
    },
  },
  imageUploader: {
    button: "Загрузить изображение",
    description:
      "Вы можете загрузить до 3 изображений. Изображения должны быть меньше 5 МБ и JPG или PNG.",
    dropHereText: "Перетащите сюда свое изображение.",
    errors: {
      fileTooLarge: "Слишком большой файл. Максимально допустимо: 5 МБ.",
      fileTooLargeChat: "Слишком большой файл. Максимально допустимо: 5 МБ.",
      fileTypeInvalid: "Недопустимый тип файла. Разрешено: jpg, jpeg, png.",
      fileTypeInvalidChat:
        "Недопустимый тип файла. Разрешено: jpg, jpeg, png, pdf, док, docx и т. д.",
      tooManyFiles:
        "Слишком много файлов. Вы можете загрузить до 5 изображений.",
      tooManyFilesChat:
        "Слишком много файлов. Вы можете отправить один за другим.",
    },
    title: "Перетащите или нажмите",
  },
  listingPages: {
    filterButton: "Фильтр по",
    filterContainerTitle: "Фильтры",
    foundCompanies: "Найденные компании:",
    foundProducts: "Найденные продукты:",
    foundTradeShows: "Найденные шоу:",
    mobileFilterButtons: {
      company: "См. Компании",
      product: "Посмотреть продукты",
      tradeShow: "См. Выставки",
    },
    noResultButton: "Очистить все фильтры",
    noResultMessage: "По вашему запросу нет результатов.",
    pageHero: {
      about: {
        description:
          "Найдите сотни сельскохозяйственных продуктов из Турции и быстро развивайте свой бизнес!",
        title: "Что такое agrowy?",
        type: "agrowy",
      },
      company: {
        description:
          "Найдите компанию, которую вы ищете, на основе нужного вам продукта.",
        title: "Сельскохозяйственные компании",
        type: "Компании",
      },
      contactUs: {
        description:
          "Есть что-то, чем вы хотели бы поделиться или предложить? Свяжитесь с нами сейчас и посмотрим, что мы сможем сделать вместе.",
        title: "Связаться с нами",
        type: "Контакт",
      },
      product: {
        description:
          "Найдите продукт, который вам нужен, легко и быстро. Свяжитесь с компанией за секунду и быстро развивайте свой бизнес!",
        title: "Сельскохозяйственные продукты из Турции",
        type: "Продукты",
      },
      tradeShow: {
        description:
          "Найдите отечественные и международные сельскохозяйственные выставки и получите самую свежую информацию о них.",
        title: "Сельскохозяйственные выставки 2022",
        type: "Шоу",
      },
    },
    searchPlaceHolders: {
      company: "Поиск компаний...",
      product: "Искать товары",
      tradeShow: "Поиск торговых выставок...",
    },
    showMoreButton: "Показать больше",
  },
  listings: {
    bottomReactions: {
      comments: "Комментарии",
      likes: "Лайки",
    },
    company: {
      category: "Категории",
      product: "Продукты",
    },
    tags: {
      "ETK\u0130NL\u0130K": "Событие",
      FUAR: "ВЫСТАВКА-ПРОДАЖА",
      REHBER: "Руководство",
      SORU: "Вопрос",
      YAZI: "Сообщение",
      new: "Новый",
      passed: "Прошли",
      "\u0130\u015E \u0130LANI": "СПИСОК ВАКАНСИЙ",
    },
  },
  login: {
    forgotPassword: "Забыли пароль?",
    invalidError: "Неверный пароль или адрес электронной почты.",
    loginButton: "Логин",
    loginWithEmail: "Войти с помощью электронной почты",
    message: "Вы успешно вошли в систему.",
    regularError: "Произошла ошибка. Пожалуйста, повторите попытку позже.",
    signup: "Создать учетную запись",
    title: "С возвращением, пожалуйста, войдите в систему.",
  },
  loginPopup: {
    mainText:
      "Создайте бесплатное членство, чтобы найти все, что вы ищете о сельскохозяйственном секторе Турции, в одном месте.",
    privacyLink: "Политика конфиденциальности",
    privacyText:
      "Мы заботимся о вашей конфиденциальности. Подробности вы можете прочитать в политике конфиденциальности.",
    topText: "Пожалуйста, войдите, чтобы продолжить.",
  },
  navigation: {
    navigationSearchButton: "Поиск",
    navigationSearchPlaceholder: "Продукты, компании, события, новости...",
    searchLink: "Поиск",
    sidebar: {
      menuItem1: "Получить справку (в прямом эфире)",
      menuItem2: "Продукты",
      menuItem2Link: "/tarim-urunleri",
      menuItem3: "Компании",
      menuItem3Link: "/tarim-sirketleri",
      menuItem5: "Календарь событий",
      menuItem5Link: "/tarim-fuarlari-takvimi",
      menuItem6: "Что такое agrowy?",
      menuItem6Link: "/agrowy-nedir",
      menuItem7: "Связаться с нами",
      menuItem7Link: "/iletisim",
      menuItem8: "GDPR",
      menuItem8Link: "/kvkk-kapsaminda-aydinlatma-metni",
      menuItem9: "Cookie Policy",
      menuItem9Link: "/cerez-politikasi",
      menuTitle1: "Быстрые ссылки",
      menuTitle2: "Узнайте больше",
      menuTitle3: "AGROWY",
      profile: "Перейти в анкету",
    },
  },
  onboarding: {
    form: {
      back: "Назад",
      finish: "Готово",
      indicator: {
        first: "Контакт",
        second: "Ожидания",
        third: "Интересы",
      },
      inputs: {
        city: {
          label: "Город",
          placeholder: "Укажите свой город",
        },
        country: {
          label: "Страна",
          placeholder: "Выберите свою страну",
        },
        expectations: {
          help: "Получение консультации по импорту из Турции.",
          learn: "Узнайте последние новости сельскохозяйственного бизнеса.",
          network: "Создайте новую бизнес-сеть.",
          product: "Откройте для себя новые продукты для импорта.",
          productSupport: "Получите поддержку от компаний-производителей.",
          title: "Каковы ваши ожидания от нас?",
        },
        import: {
          no: "У нас нет.",
          title: "Вы импортируете товары из Турции?",
          yes: "Да, у меня есть.",
        },
        importGoods: {
          chemical: "Химикаты",
          fertilizer: "удобрения",
          greenhouse: "Тепличное оборудование",
          irrigation: "Системы орошения",
          other: "Другое",
          seed: "Семена",
          title: "Что вы импортируете?",
        },
        interests: {
          biological: "#биологическийконтроль",
          biologicalDescription: "Био-продукты для контроля.",
          description: "Вы должны выбрать как минимум три из них.",
          greenhouse: "#Теплица",
          greenhouseDescription: "Конструкция и оборудование.",
          irrigation: "#ирригационныесистемы",
          irrigationDescription:
            "Откройте для себя новые продукты для импорта.",
          management: "#Управление",
          managementDescription: "Управление сельскохозяйственным бизнесом.",
          plantNutrition: "#питаниерастений",
          plantNutritionDescription: "Все об удобрениях.",
          plantProtection: "#plantprotection",
          plantProtectionDescription: "Пестициды, гербициды и др.",
          seed: "#Сеять",
          seedDescription: "Капельные, спринглерные системы и т.д.",
          title: "Какие темы вас интересуют больше всего?",
          world: "#новостиизмира",
          worldDescription:
            "Последние новости из сельскохозяйственной отрасли.",
        },
        persona: {
          consultant: "консультант",
          engineer: "Сельскохозяйственный инженер",
          farmer: "Фермер",
          hobbyist: "Любитель",
          professional: "Профессионал сектора",
          student: "Студент",
          title: "Какой из них лучше всего описывает вас?",
        },
      },
      next: "Далее",
      thankyouPage: {
        description:
          "Через секунду вы будете перенаправлены на домашнюю страницу...",
        title: "Вы успешно завершили регистрацию!",
      },
      title: "добро пожаловать в клуб. Мы можем с вами познакомиться?",
      toast: {
        first: {
          message:
            "Эта информация помогает нам как выполнять региональные настройки для вас, так и быстрее решать ваши запросы.",
          title: "Зачем вам эта информация?",
        },
        second: {
          message:
            "При желании мы будем использовать ваш номер телефона для более быстрого ответа на ваши запросы. Вы можете быть уверены, что мы не будем общаться каким-либо другим способом.",
          title: "Зачем вам эта информация?",
        },
      },
    },
    hero: {
      description:
        "Чтобы получить максимальную эффективность от agrowy, нам нужно узнать вас получше.",
      step1: "Давайте познакомимся поближе",
      step2: "Давайте разберемся с вашими ожиданиями.",
      step3: "Давайте узнаем об интересующих вас темах.",
      title: "Добро пожаловать в клуб!",
    },
  },
  pageDescriptions: {
    home: "Сотни сельскохозяйственных продуктов из Турции, сельскохозяйственных компаний и международной сельскохозяйственной выставки в agrowy.",
  },
  pageTitles: {
    "404Page": "404",
    about: "Что такое agrowy?",
    companyListing: "Сельскохозяйственные компании",
    contactUs: "Связаться с нами",
    home: "agrowy",
    login: "Логин",
    onboarding: "Стартовая настройка",
    productListing: "Сельскохозяйственные продукты из Турции",
    settings: "Настройки",
    signup: "Зарегистрироваться",
    tradeshowListing: "Сельскохозяйственные выставки 2022",
  },
  postComments: {
    editor: {
      closeButton: "Закрыть",
    },
    loadMore: "Загрузить еще",
    mainTitle: "Комментарии",
    noCommentCta: "Напишите первый комментарий!",
    noCommentTitle: "Здесь нет комментариев.",
    reply: "Ответить",
    settingsMenu: {
      delete: "Удалить",
      edit: "Редактировать",
      report: "Пожаловаться",
    },
    textarea: {
      error: "Вы должны написать не менее 5 символов!",
      fastReplies: [
        "Спасибо 🙂",
        "Отличная статья! 👏",
        "Интересно! 🤔",
        "Статья нуждается в дополнительной доработке... 👎",
      ],
      placeholder: "Напишите комментарий",
      sendButton: "Отправить",
    },
  },
  product: {
    hero: {
      liveChatInfo:
        "Вы можете общаться с нами в рабочее время Турции на английском, русском, испанском языках. Если агент поддержки недоступен, оставьте свое сообщение, и мы свяжемся с вами на следующий день после отправки.",
      ownerCompany: "Компания",
      productButtons: {
        live: "Обратиться к агенту",
        request: "Запросить информацию",
        originalLink: "Смотрите на сайте владельца ↗",
      },
    },
    otherProducts: "Другие продукты",
    requestPopup: {
      alreadyRequested: {
        contact_preference: "Предпочтение контакта",
        date: "Дата",
        description:
          "Ниже вы можете найти подробную информацию о своем запросе. Мы уже напомнили, что вы ждете, чтобы они вернулись к вам как можно скорее.",
        email: "Почта",
        mobile: "Мобильный",
        request: "Запрос",
        title: "Вы уже отправили заявку.",
        waiting: "Ожидается ответ",
      },
      form: {
        button: "Отправить",
        contactDontAsk: "Сохраните мои контактные предпочтения.",
        contactDontAskText:
          "Ваши предпочтения по контактам будут сохранены для следующих запросов. Вы всегда можете изменить контактную информацию в своем профиле.",
        contactEmail: "Почта",
        contactMobile: "Мобильный",
        contactTitle: "В какой из них лучше всего связаться с вами?",
        messagePlaceholder: "Расскажите им о своем запросе.",
        messageTitle: "Сообщение",
        title: "Заполните форму, чтобы отправить запрос",
      },
      newRequest: {
        description:
          "Вы можете найти подробную информацию о своем запросе на продукт. Вы всегда можете изменить контактную информацию в своем профиле.",
        new: "Новый",
        title: "Вы успешно отправили запрос.",
        waitingTime: "Время отклика",
        waitingTimeText: "От 1 до 3 рабочих дней",
      },
      successToast: "Ваш запрос отправлен",
    },
    reviews: {
      filter: {
        all: "Все",
        leastLiked: "Наименее понравившиеся",
        mostLiked: "Самые понравившиеся",
        topVoted: "Лидирующие",
      },
      input: {
        placeholder: "Напишите свой отзыв здесь.",
        ratingError: "Вы должны оценить от 1 до 5 звезд",
        ratingText: "Рейтинг",
      },
      inputDescription: "Помогите другим, написав отзыв прямо сейчас.",
      inputTitle: "Написать отзыв",
      loadMoreButton: "Загрузить еще",
      miniCtaText: "Написать отзыв",
      miniCtaTextFirst: "Напишите первый отзыв!",
      noReviewText: "Отзывов нет.",
      reviewReplyItem: "Ответ компании",
      reviewSectionDescription: "Отзывы пользователей.",
      reviewText: "Отзывы",
    },
    tabs: {
      labelText: "Загрузить этикетку продукта (PDF)",
      labelWarning:
        "Используйте средства защиты растений с осторожностью. Перед использованием обязательно прочитайте этикетку и информацию о продукте.",
      menu: {
        agents: "Агенты",
        description: "Описание товара",
        instructions: "Инструкции по продукту",
        reviews: "Отзывы",
      },
    },
  },
  search: {
    searchBar: {
      cta: "Поиск",
      placeholders: {
        product: "Искать товары",
      },
    },
  },
  settings: {
    bookmarks: {
      noBookmarks: "У вас нет закладок.",
      noSearchResults: "Ничего не найдено",
      searchPlaceHolder: "Поиск в закладках.",
      title: "Ваши закладки",
      titles: {
        posts: "Сообщений",
      },
    },
    ctaBox: {
      buttonText: "Получите поддержку в реальном времени",
      message: "Вам нужна помощь? Обратитесь в нашу службу поддержки.",
    },
    header: {
      bioPlaceholder: "Расскажите нам о себе в ближайшее время.",
      newMember: "Новый участник",
      producer: "Продюсер",
    },
    profile: {
      experience: {
        companyLabel: "Компания",
        companyPlaceholder: "Пожалуйста, введите название компании.",
        miniBioLabel: "Мини-био",
        miniBioLength: "Количество символов:",
        miniBioPlaceholder: "Пожалуйста, введите свою мини-биографию.",
        title: "Профессиональная информация",
        titleLabel: "Заголовок",
        titlePlaceholder: "Пожалуйста, введите название.",
      },
      messages: {
        miniBioError: "Мини-биография не может быть длиннее 200 символов.",
        success: "Профиль успешно обновлен",
      },
      personal: {
        emailLabel: "Почта",
        fnameLabel: "Имя",
        lnameLabel: "Фамилия",
        title: "Личные данные",
      },
      saveButton: "Сохранить изменения",
      social: {
        title: "Социальные сети и ссылки",
      },
    },
    requests: {
      actionsTitle: "Действия",
      company: "Компания",
      date: "Дата",
      generalRequests: "Общие запросы",
      message: "Сообщение",
      new: "Новый",
      noRequests: "У вас нет запросов.",
      product: "Продукт",
      title: "Ваши запросы",
      type: "Тип",
    },
    security: {
      accountDelete: {
        button: "Удалить учетную запись",
        confirmationDescription:
          "Введите «Имя» и «Фамилию» с пробелом между ними. Затем нажмите кнопку «Утвердить», чтобы навсегда удалить свой аккаунт.",
        confirmationNote:
          "Обратите внимание, что этот процесс необратим, и все ваши данные будут удалены навсегда, включая ваши запросы, отзывы и сообщения.",
        inputLabel: "Утвердить",
        message:
          "Нам очень жаль, что вы ушли. Если вы расскажете нам, почему вы покидаете нас, мы сделаем все возможное, чтобы улучшить наш сервис.",
        radioTitle: "В чем причина ухода?",
        title: "Удалить учетную запись",
      },
      messages: {
        accountDeleted: "Ваш аккаунт удален",
        matchError:
          "Убедитесь, что вы ввели свое имя и фамилию с пробелом между ними.",
      },
      password: {
        resetMessage:
          "Пожалуйста, отправьте запрос на сброс пароля, чтобы получить от нас электронное письмо. Следуйте инструкциям, которые мы отправили вам, чтобы сбросить пароль.",
        socialMessage:
          "Похоже, вы используете вход через социальные сети. Таким образом, вы не можете изменить свой пароль.",
        title: "Изменить пароль",
      },
    },
    sidebarMenu: {
      bookmarks: "Закладки",
      profile: "Аккаунт",
      requests: "Запросы",
      security: "Безопасность",
    },
  },
  signup: {
    alreadymember: "Вы уже являетесь участником?",
    cookie: "Политика использования файлов cookie",
    description:
      "Вы можете присоединиться к нам через минуту, используя свои учетные записи в социальных сетях и пользуясь безопасным и безопасным входом без пароля.",
    email: "Обычная регистрация",
    form: {
      consentText:
        "Я хочу получать электронные сообщения в рамках Политики конфиденциальности, чтобы получать информацию о важных кампаниях.",
      error1:
        "Этот адрес электронной почты уже используется. Пожалуйста, попробуйте войти в систему.",
      error2: "Произошла ошибка. Пожалуйста, повторите попытку позже.",
    },
    hero: {
      description:
        "Вы ищете новые продукты или новые компании для создания бизнеса? Найдите все необходимое в одном месте для развития вашего сельскохозяйственного бизнеса.",
      joinus: "Присоединитесь к нам",
      members: "Тысячи участников",
      title1: "Найди все",
      title2: "Вам нужно в одном месте.",
    },
    login: "Логин",
    privacy: "Политика конфиденциальности",
    privacyText:
      "Ваши личные данные обрабатываются в рамках Политики конфиденциальности. Нажимая одну из кнопок «Регистрация» или «Социальный аккаунт», вы подтверждаете, что прочитали и приняли Политику конфиденциальности и использования файлов cookie.",
    signup: "Регистрация",
    title: "Зарегистрироваться",
    toast: {
      error: "Произошла ошибка",
      success: "Вы успешно зарегистрировались.",
    },
  },
  time: {
    now: "Сейчас",
  },
  toast: {
    deleteComment: "Нельзя удалять комментарии, на которые есть ответы.",
    error: "Произошла ошибка",
    success: "Успешно",
  },
  tradeshow: {
    cta: {
      button: "Календарь выставок 2022",
      message:
        "Вы ищете сельскохозяйственные выставки? Ознакомьтесь с календарем сельскохозяйственных выставок!",
    },
    noExhibitors:
      "Список экспонентов не опубликован или нет экспонента, который является членом agrowy.",
    onlineRegistration: "Онлайн-регистрация",
    sidebar: {
      about: "О программе",
      exhibitors: "экспонентов",
    },
    titles: {
      eventLocation: "Место проведения мероприятия",
    },
  },
  utilities: {
    "404Page": {
      button: "Назад на главную",
      message:
        "Страница, которую вы ищете, не существует или вы ввели неправильный URL-адрес. Пожалуйста, свяжитесь с нами, если считаете, что это ошибка.",
    },
    authorBox: {
      editorInChief: "Главный редактор",
      member: "Новый участник",
    },
    errorMessages: {
      wentWrong: "Что-то пошло не так. Пожалуйста, обновите страницу.",
    },
    personas: {
      consultant: "консультант",
      engineer: "Инженер",
      farmer: "Фермер",
      hobbyist: "Любитель",
      professional: "Профессиональный",
      student: "Студент",
    },
    updatedAt: "Обновлено в:",
  },
  // Event Page
  event: {
    title: "МЕРОПРИЯТИЕ",
    button: {
      register: "Бесплатная регистрация",
      registered: "Уже зарегистрирован",
    },
    platforms: "Где смотреть?",
    titles: {
      eventContent: "Содержание события",
      speakers: "Компьютерные колонки",
    },
    footer: {
      poweredBy: "Работает на agrowy",
    },
    share: {
      linkCopied: "Ссылка скопирована в буфер обмена",
      title: "Поделитесь событием!",
      copy: "Копировать ссылку",
    },
    status: {
      live: "ЖИТЬ",
      ended: "ТРАНСЛЯЦИЯ ЗАВЕРШЕНА",
    },
    chatbox: {
      joinText:
        "Вы можете присоединиться к чату, чтобы задать вопросы спикерам и познакомиться с другими участниками.",
      joinButton: "Присоединиться к чату",
      emptyText: "Сообщений пока нет.",
    },
    live: {
      startingText: "Мероприятие начнется через несколько минут.",
      startingTextJoin: "Не забудьте присоединиться к чату во время ожидания.",
      endText: "Мероприятие завершилось. Спасибо за ваше участие.",
    },
  },
  // Featured TradeShow
  featuredTradeShow: {
    badge: "Избранная выставка",
    button1: "Учить больше",
    button2: "Зарегистрируйтесь бесплатно",
  },
};

export default ru;
