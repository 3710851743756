import en from "../translations/english";
import ru from "../translations/russian";
import es from "../translations/spanish";

const getTranslation = (locale) => {
  if (locale === "en") {
    return en;
  }
  if (locale === "ru") {
    return ru;
  }
  if (locale === "es") {
    return es;
  }
};

export default getTranslation;
